@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");
/*  Обнуление   */

* {
	padding: 0;
	margin: 0;
	border: 0;
}
*,
*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
:focus,
:active {
	outline: none;
}
a:focus,
a:active {
	outline: none;
}

nav,
footer,
header,
aside {
	display: block;
}

html,
body {
	height: 100%;
	width: 100%;
	font-size: 100%;
	line-height: 1;
	font-size: 14px;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}
input,
button,
textarea {
	font-family: inherit;
	color: white;
}

input::-ms-clear {
	display: none;
}
button {
	cursor: pointer;
}
button::-moz-focus-inner {
	padding: 0;
	border: 0;
}
a,
a:visited {
	text-decoration: none;
}
a:hover {
	text-decoration: none;
}
ul li {
	list-style: none;
}
img {
	vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: 400;
}

html,
body {
	padding: 0;
	margin: 0;
	font-family:
		-apple-system,
		BlinkMacSystemFont,
		Segoe UI,
		Roboto,
		Oxygen,
		Ubuntu,
		Cantarell,
		Fira Sans,
		Droid Sans,
		Helvetica Neue,
		sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	font-family: Montserrat;
	box-sizing: border-box;
}

input,
textarea {
	background-color: #fff;
	border: none;
}

iframe {
	border-radius: 3px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	min-height: 300px;
}

/* main layout start */
.logo {
	height: 60px;
	padding: 16px;
	display: grid;
	place-items: center;
	color: white;
	font-weight: 800;
	/* background: rgba(255, 255, 255, 0.2); */
}
.site-layout .site-layout-background {
	background: #fff;
}
[data-theme="dark"] .site-layout .site-layout-background {
	background: #e72525;
}

.ant-layout-content {
	margin: 16px;
}
.site-layout-background {
	padding: 16px;
}

@media (max-width: 500px) {
	.ant-layout-content {
		margin: 0;
		margin-top: 15px;
	}
	.site-layout-background {
		padding: 5px;
	}
}

.site-layout {
	margin-left: 80px;
}

.ant-layout-sider-trigger {
	display: none;
}
.main-burger {
	display: none;
	position: absolute;
	top: 20px;
	left: 18px;
	transition: 0.3s all;
}
.main-burger svg {
	width: 25px;
	height: 25px;
}
@media (max-width: 500px) {
	.main-menu {
		display: none;
	}
	.main-burger {
		display: block;
	}
	.site-layout {
		margin-left: 0;
	}
}

.ant-table-cell {
	text-align: center;
}

/* рамер картинки в меню */
/* .ant-menu-item-icon svg{
	font-size: 18px;
} */
/* main layout end */

@media (prefers-color-scheme: dark) {
	html {
		color-scheme: dark;
	}
	body {
		color: white;
		background: black;
	}
}
/* иконка телеграмма в меню */
.telegram {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	/* width: 20px;
	height: 20px; */
	text-align: center;
	display: grid;
	place-items: center;
	grid-auto-flow: row;
	/* margin-top: 40px; */

	background-color: rgb(85, 167, 228);
	/* background-color: purple; */
	/* border-radius: 30px; */
	cursor: pointer;
}
.telegram svg {
	margin: 20px 0;
	width: 30px;
	height: 30px;
}
.telegram svg path {
	/* width: 40px;
	height: 20px; */
	transform: scale(0.6);
}
.telegram_text {
	/* display: inline-block; */
	/* justify-self: start; */
	margin: 0;
	color: white;
}

/* убрать боковое меню таба с вложениями */
.ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-dark.ant-menu-submenu-placement-rightTop {
	display: none;
}
