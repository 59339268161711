/* @import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap'); */

@font-face {
	font-family: "Teko";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(fonts/teko/Teko-Light.woff) format("woff");
}

@font-face {
	font-family: "Teko";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(fonts/teko/Teko-Regular.woff) format("woff");
}

@font-face {
	font-family: "Teko";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(fonts/teko/Teko-Medium_1.woff) format("woff");
}

@font-face {
	font-family: "Teko";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(fonts/teko/Teko-Bold.woff) format("woff");
}

@font-face {
	font-family: "Teko";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(fonts/teko/Teko-SemiBold.woff) format("woff");
}

body {
	margin: 0;
	font-family: Teko !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
}

* {
	font-family: Teko !important;
}

#root,
html,
body {
	height: 100%;
	min-height: 100%;
	/* height: 100dvh;
	min-height: 100dvh; */
	background-color: rgba(0, 0, 0, 0.05);
}

.ant-notification-notice-message {
	font-size: 20px !important;
}